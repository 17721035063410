import { createSlice } from '@reduxjs/toolkit';
import { initialRequestStatus, setLoadingReducer, setErrorReducer } from '@ionnyk-npm/common-ts';
import { authGroupApi } from '@/api-services';
import { FrameDTO } from './FrameDTO';
import { makeThunk } from '@/utils/toCommon';

/**
 * ======= ACTIONS (ASYNC) ======
 */

export const fetchFrame = makeThunk<FrameDTO, string>('fetchFrame', async (uuid: string) => {
  console.info('fetchFrame start GET', uuid);
  return await authGroupApi.get(`/frames/${uuid}`);
});

const initialState = {
  total: 0,
  items: {} as Record<string, FrameDTO>,
};
export const framesSlice = createSlice({
  name: 'frames',
  initialState: { ...initialRequestStatus, ...initialState },
  reducers: {
    addMembers: (state, action) => {},
  },
  extraReducers: (bd) => {
    bd.addCase(fetchFrame.pending, setLoadingReducer);
    bd.addCase(fetchFrame.rejected, setErrorReducer);
    bd.addCase(fetchFrame.fulfilled, (state, action) => {
      const frame = action.payload;
      // FIXME clean this; update is impossible otherwise
      // if (!(frame.uuid in state.items)) {
      state.items[frame.uuid] = {
        ...frame,
        // FIXME isConnected and other monitoring flags
      };
      if (!(frame.uuid in state.items)) {
        state.total++;
      }
      // }
      state.loading = false;
    });
  },
});
export const { addMembers } = framesSlice.actions;
