import { logoutAsync } from '@/features-slice/auth/slice/signinSlice';
import { store } from '@/stores/store';
import { axiosBaseConfig, UNAUTHORIZED_STATUS } from '@ionnyk-npm/common-ts';
import axios, { AxiosInstance } from 'axios';

// @ts-ignore
export const ENV_VARS = import.meta.env; // not process.env! (vite)
console.info(ENV_VARS?.NODE_ENV)
export const __DEV__ = ENV_VARS?.NODE_ENV === 'development'|| ENV_VARS?.DEV; // vite
console.info(__DEV__ ? 'development env' : 'deployed env - debug logs disabled');
if (!__DEV__ ) {
  console.debug = () => {};
  console.log = () => {};
}
export type LocalConfig = { 
  SITE_KEY?: string 
  TENANT?: string
  GCP_API_KEY ?: string
  GCP_AUTH_DOMAIN?: string
};
export const localConfig: LocalConfig = {
  SITE_KEY: '',
  TENANT: '',
  GCP_API_KEY: ENV_VARS.VITE_API_GCP_API_KEY,
  GCP_AUTH_DOMAIN: ENV_VARS.VITE_API_GCP_AUTH_DOMAIN,
};
export const apiUrl = (() => {
  switch (window.location.origin) {
    case 'https://b2b-portal.ionnyk-prod.inkcoming.eu': {
      localConfig.SITE_KEY = ENV_VARS.VITE_API_SITE_KEY_PROD;
      localConfig.TENANT = ENV_VARS.VITE_API_TENANT_PROD;
      return ENV_VARS.VITE_API_BASE_URL_PROD;
    }
    case 'https://b2b-portal.ionnyk-staging.inkcoming.eu': {
      localConfig.SITE_KEY = ENV_VARS.VITE_API_SITE_KEY_STAGING;
      localConfig.TENANT = ENV_VARS.VITE_API_TENANT_STAGING;
      return ENV_VARS.VITE_API_BASE_URL_STAGING;
    }
    case 'https://b2b-portal.ionnyk-dev.inkcoming.eu':
    case 'http://localhost:3000':
    case 'http://127.0.0.1:3000':
    default:
      localConfig.SITE_KEY = ENV_VARS.VITE_API_SITE_KEY_DEV;
      localConfig.TENANT = ENV_VARS.VITE_API_TENANT_DEV;
      return ENV_VARS.VITE_API_BASE_URL_DEV;
  }
})();
console.info('apiUrl', apiUrl,'GENERATE_SOURCEMAP=', ENV_VARS.GENERATE_SOURCEMAP );

const BASE_URL = apiUrl + '/client/v1/';
const BASE_URL_GROUP = apiUrl + '/network-manager/v1/';
const BASE_URL_ANON = apiUrl + '/anon/v1/';
const BASE_URL_ADMIN = apiUrl + '/admin/v1/'; // FIXME migrate

export const anonApi: AxiosInstance = axios.create({
  ...axiosBaseConfig,
     baseURL: BASE_URL_ANON,
});

export const authApi: AxiosInstance = axios.create({
  ...axiosBaseConfig,
    baseURL: BASE_URL,
});

export const authGroupApi: AxiosInstance = axios.create({
  ...axiosBaseConfig,
    timeout: 20000, // FIXME optim API
    baseURL: BASE_URL_GROUP,
});

export const adminApi: AxiosInstance = axios.create({
  ...axiosBaseConfig,
    baseURL: BASE_URL_ADMIN,
});

// FIXME update common-ts
const interceptorsMap: Record<string, number|null> = {};
export function installBearerAuthHeader(axiosInstance: AxiosInstance, apiName: string, token: string) {
  axiosInstance.interceptors.request.clear();
  axiosInstance.interceptors.request.eject(interceptorsMap[apiName]);
  interceptorsMap[apiName] = axiosInstance.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  axiosInstance.interceptors.response.clear();
  axiosInstance.interceptors.response.eject(interceptorsMap[`response-${interceptorsMap[apiName]}`]);
  interceptorsMap[`response-${interceptorsMap[apiName]}`] = axiosInstance.interceptors.response.use(
    (response) => {
      if(response.status === UNAUTHORIZED_STATUS && window.confirm('Session expired. Please login again')) {
        store.dispatch(logoutAsync());
      }
      return response;
    },
    (error) => Promise.reject(error)
  );
}

export const onBearerTokenRefresh = (bearerToken: string) => {
  installBearerAuthHeader(authApi, 'authApi', bearerToken);
  installBearerAuthHeader(authGroupApi, 'authGroupApi', bearerToken);
  installBearerAuthHeader(adminApi, 'adminApi', bearerToken);
};