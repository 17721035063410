import { createAsyncThunk, createSlice, unwrapResult } from '@reduxjs/toolkit';
import {
  APIReject,
  initialRequestStatus,
  handleNetwError,
  setLoadingReducer,
  setErrorReducer,
  sleep,
} from '@ionnyk-npm/common-ts';
import { authGroupApi } from '@/api-services';
import { DecoratedPairing, PairingDTO } from './PairingDTO';
import { fetchFrame } from '../frames/FrameSlice';
import { privateAddEntity } from '@/utils/toCommon';

/**
 * ======= ACTIONS (ASYNC) ======
 */

// FIXME -> GROUPSlice

export const fetchPairings = createAsyncThunk<
  DecoratedPairing[], // output
  string, // input
  APIReject // error output
>('fetchPairings', async (uuid: string, thunkAPI) => {
  try {
    console.info('fetchPairings start GET', uuid);
    let pairings: PairingDTO[] =
      (await authGroupApi.get(`/groups/${uuid}/pairings?deep=true`))?.data ?? ([] as PairingDTO[]);
    pairings = pairings.filter((p) => p.pairingStatus === 'ACTIVE' && !!p.frameUuid);
    const promises = pairings.map(async (p: PairingDTO) => {
      await sleep(500); // FIXME to avoid charging frame API too much
      const frameDto = await thunkAPI.dispatch(fetchFrame(p.frameUuid)).then(unwrapResult);
      return {
        ...p,
        ...frameDto,
      } as DecoratedPairing;
    }); // FIXME
    const newFrames = await Promise.all(promises);
    return newFrames;
  } catch (e) {
    return thunkAPI.rejectWithValue(handleNetwError('fetchPairings', e, null, [], true));
  }
});

const initialState = {
  total: 0,
  items: {} as Record<string, DecoratedPairing>,
};
export const pairingsSlice = createSlice({
  name: 'pairings',
  initialState: { ...initialRequestStatus, ...initialState },
  reducers: {
    addMembers: (state, action) => {},
  },
  extraReducers: (bd) => {
    // pairings
    bd.addCase(fetchPairings.pending, setLoadingReducer);
    bd.addCase(fetchPairings.rejected, setErrorReducer);
    bd.addCase(fetchPairings.fulfilled, (state, action) => {
      const pairings = action.payload;
      pairings.forEach((pairing) => {
        privateAddEntity(state, { ...pairing, uuid: pairing.pairingUuid });
      });
      state.loading = false;
    });
  },
});
export const { addMembers } = pairingsSlice.actions;
