import { __DEV__ } from '@/api-services';
import { AuthError, EMAIL_NOT_VERIFIED, MFA_REQUIRED, MISSING_PHONE_2FA, MISSING_USER_ERROR, MISSING_VALIDATION_ID, NO_ID_TOKEN, RELOGIN_REQUIRED, WRONG_PASSWORD } from '@/api-services/AuthError';
import { logoutAsync } from '@/features-slice/auth/slice/signinSlice';
import { getDefaultTranslator } from '@/i18n/useTranslation';
import { store } from '@/stores/store';
import {
  APIReject,
  CLIENT_BAD_REQUEST_STATUS_STRING,
  UNKNOWN_ERROR_KEY,
  setErrorReducer,
  setLoadingReducer,
} from '@ionnyk-npm/common-ts';
import {
  createAsyncThunk,
  AsyncThunkPayloadCreator,
  AsyncThunk,
  Dispatch,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
  createSlice,
  ActionReducerMapBuilder,
  CaseReducer,
} from '@reduxjs/toolkit';
import { AsyncThunkFulfilledActionCreator } from '@reduxjs/toolkit/dist/createAsyncThunk';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import _ from 'lodash';

// FIXME type state WritableDraft<ItemsResponse<E>> | undefined
export const privateAddEntity = <E extends { uuid: string }>(state: any, entity: E) => {
  if (!(entity.uuid in state.items)) {
    state.items[entity.uuid] = entity;
    state.total++;
  }
};

export const makeThunk = <Returned, ThunkArg>(
  type: string,
  apiFn: (arg: ThunkArg) => Promise<AxiosResponse>
) =>
  createAsyncThunk<
    Returned, // output
    ThunkArg, // input
    APIReject // error output
  >(type, async (arg, thunkAPI) => {
    try {
      console.info('API-' + type, arg);
      const response = (await apiFn(arg))?.data;
      return response as Returned;
    } catch (e) {
      return thunkAPI.rejectWithValue(handleNetworkError(type, e, null, [], true));
    }
  });

export const addGenericCase = <R, A, S>(
  bd: ActionReducerMapBuilder<S>,
  thunkFn: AsyncThunk<R, A, APIReject>,
  reducer: CaseReducer<S, ReturnType<AsyncThunkFulfilledActionCreator<R, A, APIReject>>>
) => {
  bd.addCase(thunkFn.pending, setLoadingReducer);
  bd.addCase(thunkFn.rejected, setErrorReducer);
  bd.addCase(thunkFn.fulfilled, reducer);
};

type AsyncThunkConfig = {
  /** return type for `thunkApi.getState` */
  state?: unknown;
  /** type for `thunkApi.dispatch` */
  dispatch?: Dispatch;
  /** type of the `extra` argument for the thunk middleware, which will be passed in as `thunkApi.extra` */
  extra?: unknown;
  /** type to be passed into `rejectWithValue`'s first argument that will end up on `rejectedAction.payload` */
  rejectValue?: unknown;
  /** return type of the `serializeError` option callback */
  serializedErrorType?: unknown;
  /** type to be returned from the `getPendingMeta` option callback & merged into `pendingAction.meta` */
  pendingMeta?: unknown;
  /** type to be passed into the second argument of `fulfillWithValue` to finally be merged into `fulfilledAction.meta` */
  fulfilledMeta?: unknown;
  /** type to be passed into the second argument of `rejectWithValue` to finally be merged into `rejectedAction.meta` */
  rejectedMeta?: unknown;
};

/**
 * const {fetchSubGroups, fetchSubGroupsCase} = makeAll<ItemsResponse<Group>, string, typeof initialState>(
  'fetchSubGroups',
  (parentGroupUuid) => authGroupApi.get(`/groups?parentGroupUuid=${parentGroupUuid}`),
  (state, action) => {
    state.total = action.payload.total; // FIXME numberOfItems
    state.items = array2Map(action.payload.items, (g) => g.uuid);
    state.loading = false;
  }
);
 * FIXME     fetchSubGroupsCase(bd); type error
 * @param type 
 * @param apiFn 
 * @param reducer 
 * @returns 
 */

export const makeAll = <R, A, S>(
  type: string,
  apiFn: (arg: A) => Promise<AxiosResponse>,
  reducer: CaseReducer<S, ReturnType<AsyncThunkFulfilledActionCreator<R, A, APIReject>>>
) => {
  const thunkFn = makeThunk<R, A>(type, apiFn);
  return {
    [type]: thunkFn,
    [`${type}Case`]: (bd: ActionReducerMapBuilder<S>) =>
      addGenericCase<R, A, S>(bd, thunkFn, reducer),
  };
};

export const createMyOwnAsyncThunk = <Returned, ThunkArg = any>(
  type: string,
  thunk: AsyncThunkPayloadCreator<Returned, ThunkArg, AsyncThunkConfig> // <-- very unsure of this - have tried many things here
): AsyncThunk<Returned, ThunkArg, AsyncThunkConfig> => {
  return createAsyncThunk<Returned, ThunkArg, AsyncThunkConfig>(
    type,
    // @ts-ignore
    async (arg: ThunkArg, thunkAPI) => {
      try {
        // do some stuff here that happens on every action
        return await thunk(arg, thunkAPI);
      } catch (err) {
        // do some stuff here that happens on every error
        return thunkAPI.rejectWithValue(null);
      }
    }
  );
};

interface GenericState<T> {
  data?: T;
  status: 'loading' | 'finished' | 'error';
}

const createGenericSlice = <T, Reducers extends SliceCaseReducers<GenericState<T>>>({
  name = '',
  initialState,
  reducers,
}: {
  name: string;
  initialState: GenericState<T>;
  reducers: ValidateSliceCaseReducers<GenericState<T>, Reducers>;
}) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      start(state) {
        state.status = 'loading';
      },
      /**
       * If you want to write to values of the state that depend on the generic
       * (in this case: `state.data`, which is T), you might need to specify the
       * State type manually here, as it defaults to `Draft<GenericState<T>>`,
       * which can sometimes be problematic with yet-unresolved generics.
       * This is a general problem when working with immer's Draft type and generics.
       */
      success(state: GenericState<T>, action: PayloadAction<T>) {
        state.data = action.payload;
        state.status = 'finished';
      },
      ...reducers,
    },
  });
};

/**
   * const wrappedSlice = createGenericSlice({
  name: 'test',
  initialState: { status: 'loading' } as GenericState<string>,
  reducers: {
    magic(state) {
      state.status = 'finished'
      state.data = 'hocus pocus'
    },
  },
})
   */

export function uuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.floor(Math.random() * 16);
    if (c === 'x') {
      return r.toString(16);
    } else {
      const v = (r % 4) + 8; // This ensures that the number is in the [8, 9, A, or B] range
      return v.toString(16);
    }
  });
}

export const truncateUuid = (uuid: string) => _.truncate(uuid, { length: 8 });

// FIXME to common-ts
export const TIMEOUT_ERROR_KEY = 'TIMEOUT';
export const NETWORK_ERROR = 'NETWORK_ERROR';
export const CLIENT_ERROR = 'CLIENT_ERROR';

const ALL_ERROR_KEYS = [
  UNKNOWN_ERROR_KEY,
  TIMEOUT_ERROR_KEY,
  NETWORK_ERROR,
  CLIENT_BAD_REQUEST_STATUS_STRING,
  CLIENT_ERROR,
  // AuthError:
  EMAIL_NOT_VERIFIED,
  MFA_REQUIRED,
  WRONG_PASSWORD,
  NO_ID_TOKEN,
  RELOGIN_REQUIRED,
  MISSING_USER_ERROR,
  MISSING_PHONE_2FA,
  MISSING_VALIDATION_ID,
] as const;
export type APIErrorKey = (typeof ALL_ERROR_KEYS)[number];

// FIXME string vs number
export const SERVER_ERROR_STATUS = '500';
export const CUSTOM_CONFLICT_STATUS = '409';
export const CLIENT_BAD_REQUEST_STATUS = '400';
export const UNAUTHORIZED_STATUS = '401';
export const NOT_FOUND_STATUS = '404';
export const FORBIDDEN_STATUS = '403';

const ALL_STATUS_CODES = [
  CUSTOM_CONFLICT_STATUS,
  NOT_FOUND_STATUS,
  SERVER_ERROR_STATUS,
  CLIENT_BAD_REQUEST_STATUS,
  UNAUTHORIZED_STATUS,
  FORBIDDEN_STATUS,
] as const;
export type APIErrorStatusCode = (typeof ALL_STATUS_CODES)[number];

export type APIErrorClient = {
  errorKey: APIErrorKey;
  submittedData: any;
  statusCode?: APIErrorStatusCode;
  desc?: string;
  // FIXME https://redux.js.org/faq/organizing-state#can-i-put-functions-promises-or-other-non-serializable-items-in-my-store-state
  originalError?: any;
};

export const handleNetworkError = (
  src: string,
  e: any,
  submittedData: any = {},
  managedClientErrors: APIErrorStatusCode[] = [], // any error status we want to display in custom fashion
  alerting = true
) => {
  console.warn('handleNetworkError() for ' + src, {
    name: e.name,
    message: e.message,
    submittedData,
  });

  const uiError: APIErrorClient = {
    errorKey: UNKNOWN_ERROR_KEY,
    statusCode: undefined,
    desc: e?.message,
    submittedData,
    originalError: e,
  };

  if (axios.isAxiosError(e)) {
    const { code, status, message, name, response, config } = e;
    const { data} = response ?? {};
    __DEV__ && console.warn(
      'is axios error',
      { code, status, message, name, response, config },
      {
        // client config
        method: config?.method,
        fullURL: `${config?.baseURL}${config?.url}`,
        params: config?.params,
        // @ts-ignore
        body: config?.body ?? config?.data,
        // http layer
        status: response?.status,
        statusText: response?.statusText,
        data,
        // Server specific
        error: data?.error,
        code: data?.code,
        description: data?.description,
      }
    );
    if (message.includes('Network Error')) {
      uiError.statusCode = SERVER_ERROR_STATUS;
      uiError.errorKey = NETWORK_ERROR;
    }
    if (message.includes('timeout')) {
      uiError.statusCode = SERVER_ERROR_STATUS;
      uiError.errorKey = TIMEOUT_ERROR_KEY;
    }
    if (response) {
      const { data, status, statusText } = response;
      const { error: dataError, code: dataCode, description: dataDescription } = data ?? {};
      console.warn('response error', {
        data,
        status,
        statusText,
        dataError,
        dataCode,
        dataDescription,
      });
      uiError.statusCode = String(status) as APIErrorStatusCode;
      uiError.errorKey = dataCode ?? dataError ?? name;
      uiError.desc = dataDescription ?? message ?? statusText ?? name;
      switch (uiError.statusCode) {
        case CUSTOM_CONFLICT_STATUS:
          break;
        case NOT_FOUND_STATUS:
          break;
        case CLIENT_BAD_REQUEST_STATUS:
          uiError.errorKey = CLIENT_BAD_REQUEST_STATUS_STRING;
          break;
        case UNAUTHORIZED_STATUS:
          // FIXME unconventional shortcut flow here
          console.warn('UNAUTHORIZED_STATUS 401', 'Disconnecting firebase user for eventual token expiration...');
          alert(getDefaultTranslator('RELOGIN_REQUIRED'));
          store.dispatch(logoutAsync());
          return;
        case FORBIDDEN_STATUS:
          break;
        default:
          uiError.errorKey = UNKNOWN_ERROR_KEY;
          break;
      }
      if (managedClientErrors?.includes(uiError.statusCode)) {
        uiError.errorKey = CLIENT_ERROR;
        alerting = false;
      }
    } else {
      // no response
      uiError.errorKey = UNKNOWN_ERROR_KEY;
      uiError.desc = message ?? name;
    }
  } else {
    // no axios
    uiError.errorKey =  e?.errorKey ?? UNKNOWN_ERROR_KEY;
    uiError.desc = e?.message;
    if(e instanceof AuthError){
      uiError.errorKey = e.errorKey as APIErrorKey;
    } else {
      console.error('loginAsync', e);
    }
  }
  if (alerting) {
    const t = getDefaultTranslator;
    alert(`${t(uiError.errorKey as any)} - ${uiError.desc}`);
  }

  return uiError;
};

const interceptorsMap: Record<string, number|null> = {};
export function installBasicAuthHeader(authApi: AxiosInstance, apiName: string, token: string) {
  const interceptorID = interceptorsMap[apiName];
  if (interceptorID) {
    authApi.interceptors.request.eject(interceptorID);
  }
  interceptorsMap[apiName] = authApi.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Basic ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
}
