import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  APIReject,
  initialRequestStatus,
  handleNetwError,
  setLoadingReducer,
  setErrorReducer,
  ItemsResponse,
} from '@ionnyk-npm/common-ts';
import { BatchResponse } from './batchDTO';
import { privateAddEntity, uuidv4 } from '@/utils/toCommon';
import { ArtworkDTO } from '../artworks/ArtworkDTO';
import _ from 'lodash';
import { authGroupApi } from '@/api-services';
/**
 * ======= ACTIONS (ASYNC) ======
 */
const basePath = '/batches';
export const fetchBatches = createAsyncThunk<
  BatchResponse[], // output
  void, // input
  APIReject // error output
>('fetchBatches', async (_, thunkAPI) => {
  console.info('fetchbatches!')
  try {
    return (await authGroupApi.get(`${basePath}`))?.data as BatchResponse[];
  } catch (e) {
    return thunkAPI.rejectWithValue(handleNetwError('fetchBatches', e, null, [], true));
  }
});

export const fetchBatch = createAsyncThunk<
  ItemsResponse<ArtworkDTO>, // output
  string, // input // FIXME use query params + pagination instead
  APIReject // error output
>('fetchBatch', async (uuid: string, thunkAPI) => {
  try {
    // FIXME orientation
    const response = (await authGroupApi.get(`${basePath}/${uuid}/artworks?page=0&frameModel=LINN&size=9999`))?.data; // FIXME pagination
    return response as ItemsResponse<ArtworkDTO>;
  } catch (e) {
    return thunkAPI.rejectWithValue(handleNetwError('fetchBatch', e, null, [], true));
  }
});

const initialState = {
  total: 0,
  items: {} as Record<string, BatchResponse>,
};
export const batchesSlice = createSlice({
  name: 'batches',
  initialState: { ...initialRequestStatus, ...initialState },
  reducers: {},
  extraReducers: (bd) => {
    // batches
    bd.addCase(fetchBatches.pending, setLoadingReducer);
    bd.addCase(fetchBatches.rejected, setErrorReducer);
    bd.addCase(fetchBatches.fulfilled, (state, action) => {
      console.info('fetchBatches.fulfilled', action.payload)
      const batches = action.payload;
      batches.forEach((batch) => {
        privateAddEntity(state, {
          ...batch,
          artworksUUIDs: [],
        });
      });
      state.loading = false;
      state.initialLoading = 'SUCCESS';
    });

    // batch content
    bd.addCase(fetchBatch.pending, setLoadingReducer);
    bd.addCase(fetchBatch.rejected, setErrorReducer);
    bd.addCase(fetchBatch.fulfilled, (state, action) => {
      const uuid = action.meta.arg;
      const batch = state.items[uuid];
      const batchContent = action.payload;
      batchContent.items.forEach(({ uuid }) => {
        batch.artworksUUIDs.push(uuid);
      });
      state.loading = false;
    });
  },
});
// export const { } = batchesSlice.actions;
