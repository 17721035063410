import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  APIReject,
  initialRequestStatus,
  handleNetwError,
  setLoadingReducer,
  setErrorReducer,
  sleep,
} from '@ionnyk-npm/common-ts';
import { authApi } from '@/api-services';
import { ArtworkDTO } from './ArtworkDTO';
import { privateAddEntity } from '@/utils/toCommon';
import { fetchBatch } from '../batch/batchSlice';
import { JVMock } from './mock';

/**
 * ======= ACTIONS (ASYNC) ======
 */


export const fetchArtwork = createAsyncThunk<
  ArtworkDTO, // output
  string, // input
  APIReject // error output
>('fetchArtwork', async (uuid: string, thunkAPI) => {
  try {
    const response = (await authApi.get(`/artworks/${uuid}`))?.data;
    return response as ArtworkDTO;
  } catch (e) {
    console.error('fetchArtwork() slice', e);
    return thunkAPI.rejectWithValue(handleNetwError('fetchArtwork', e, null, [], true));
  }
});

const initialState = {
  total: 0,
  items: {} as Record<string, ArtworkDTO>,
};

export const artworksSlice = createSlice({
  name: 'artworks',
  initialState: { ...initialRequestStatus, ...initialState },
  reducers: {
    addMembers: (state, action) => {},
  },
  extraReducers: (bd) => {
    bd.addCase(fetchArtwork.pending, setLoadingReducer);
    bd.addCase(fetchArtwork.rejected, setErrorReducer);
    bd.addCase(fetchArtwork.fulfilled, (state, action) => {
      const artwork = action.payload;
      privateAddEntity(state, artwork);
      // if(!(artwork.uuid in state.items)) {
      //   state.items[artwork.uuid] = artwork;
      //   state.total++;
      // }
      state.loading = false;
    });

    // batch content
    bd.addCase(fetchBatch.pending, setLoadingReducer);
    bd.addCase(fetchBatch.rejected, setErrorReducer);
    bd.addCase(fetchBatch.fulfilled, (state, action) => {
      const batch = action.payload;
      batch.items.forEach((artwork) => {
        privateAddEntity(state, artwork);
      });
      state.loading = false;
    });
  },
});
export const { addMembers } = artworksSlice.actions;
